document.addEventListener("DOMContentLoaded", function() {
    let select_links= document.querySelectorAll('.wp-block-starter-select-link div');
    
    if(select_links.length!=0){
      if(select_links.length>1){
        
        select_links.forEach((item) => {

            let selectElement = document.createElement('select');

            let optionDefaultElement = document.createElement('option');
            optionDefaultElement.innerHTML="Sélectionner";
            optionDefaultElement.setAttribute('selected',"");
            optionDefaultElement.setAttribute('disabled',"");

            selectElement.appendChild(optionDefaultElement);
            while (item.firstChild) {
              // Déplacez chaque enfant vers le nouvel élément select
              selectElement.appendChild(item.firstChild);
            }
            item.parentNode.replaceChild(selectElement,item);

            selectElement.addEventListener("change", (event) => {
                let link=selectElement.options[selectElement.selectedIndex].getAttribute('data-link');
                window.location.href=link;
            });
        });
      }else{
        let selectElement = document.createElement('select');

        let optionDefaultElement = document.createElement('option');
        optionDefaultElement.innerHTML="Sélectionner";
        optionDefaultElement.setAttribute('selected',"");
        optionDefaultElement.setAttribute('disabled',"");

        selectElement.appendChild(optionDefaultElement);
        while (select_links[0].firstChild) {
          // Déplacez chaque enfant vers le nouvel élément select
          selectElement.appendChild(select_links[0].firstChild);
        }
        select_links[0].parentNode.replaceChild(selectElement, select_links[0]);

        selectElement.addEventListener("change", (event) => {
            let link=selectElement.options[selectElement.selectedIndex].getAttribute('data-link');
            window.location.href=link;
        });
      }
    }


});